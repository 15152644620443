import React from "react";
import * as images from "./images";
import * as socialMediaIcons from "./socialMediaIcons";
import "./App.css";

const URL_HASHES = {
  home: "",
  ourMission: "mission",
  ourApproach: "approach",
  ourCommitment: "commitment",
  whatWeFund: "what-we-fund",
  volunteer: "volunteer",
  otherWaysToHelp: "other-ways-to-help",
  supporters: "supporters",
  eventsCalendar: "events-calendar",
  brightChildScholarshipDetails: "bright-child-scholarship",
  hostAnEvent: "host-an-event",
  scholarshipsList: "scholarships-list",
  promoteAScholarship: "promote-a-scholarship",
  donateMoney: "donate-money",
  donateGoodsAndServices: "donate-goods-and-services",
} as const;

const URL_HASHES_WHERE_THE_ABOUT_NAV_LINK_IS_CONSIDERED_ACTIVE: readonly (typeof URL_HASHES)[keyof typeof URL_HASHES][] =
  [
    URL_HASHES.ourMission,
    URL_HASHES.ourApproach,
    URL_HASHES.ourCommitment,
    URL_HASHES.whatWeFund,
  ];

const URL_HASHES_WHERE_THE_CONTRIBUTE_NAV_LINK_IS_CONSIDERED_ACTIVE: readonly (typeof URL_HASHES)[keyof typeof URL_HASHES][] =
  [URL_HASHES.volunteer, URL_HASHES.otherWaysToHelp];

const URL_HASHES_WHERE_THE_EVENTS_NAV_LINK_IS_CONSIDERED_ACTIVE: readonly (typeof URL_HASHES)[keyof typeof URL_HASHES][] =
  [URL_HASHES.eventsCalendar, URL_HASHES.hostAnEvent];

const URL_HASHES_WHERE_THE_SCHOLARSHIPS_NAV_LINK_IS_CONSIDERED_ACTIVE: readonly (typeof URL_HASHES)[keyof typeof URL_HASHES][] =
  [URL_HASHES.scholarshipsList, URL_HASHES.promoteAScholarship];

const EXTERNAL_LINKS = {
  contactUsForm:
    "https://docs.google.com/forms/d/e/1FAIpQLScj3DEvcB90_42Z158EVAHXFKDFr8zgzS882klLCz4tdL0TNA/viewform?usp=sf_link",
} as const;

const SCHOLARSHIP_DEADLINES = {
  brightChild: new Date(
    Date.UTC(2024, 6, 26, 23, 59, 0, 0) + 7 * 60 * 60 * 1000
  ),
  ellieOzeki: new Date(
    Date.UTC(2024, 5, 28, 23, 59, 0, 0) + 7 * 60 * 60 * 1000
  ),
  digitalDreamers: new Date(
    Date.UTC(2024, 5, 28, 23, 59, 0, 0) + 7 * 60 * 60 * 1000
  ),
  techTrailblazers: new Date(
    Date.UTC(2024, 1, 16, 23, 59, 0, 0) + 8 * 60 * 60 * 1000
  ),
} as const;

const DEFAULT_DONATION_FORM_STATE: DonationFormState = {
  donationAmount: "",
  coverTransactionCosts: false,
  firstName: "",
  lastName: "",
  phoneNumber: "",
  emailAddress: "",
  mailingAddress: "",
  creditCardNumber: "",
  creditCardExpirationDate: "",
  cvv: "",
};

interface State {
  readonly hash: string;
  readonly dateDotNow: number;

  readonly donationForm: DonationFormState;
}

interface DonationFormState {
  readonly donationAmount: string;
  readonly coverTransactionCosts: boolean;
  readonly firstName: string;
  readonly lastName: string;
  readonly phoneNumber: string;
  readonly emailAddress: string;
  readonly mailingAddress: string;
  readonly creditCardNumber: string;
  readonly creditCardExpirationDate: string;
  readonly cvv: string;
}

export class App extends React.Component<{}, State> {
  private shouldUpdateDateDotNow: boolean;

  constructor(props: {}) {
    super(props);

    this.shouldUpdateDateDotNow = true;
    this.state = {
      hash: window.location.hash,
      dateDotNow: Date.now(),
      donationForm: DEFAULT_DONATION_FORM_STATE,
    };

    this.bindMethods();
  }

  bindMethods(): void {
    this.onHomeLinkClicked = this.onHomeLinkClicked.bind(this);
    this.onOurMissionLinkClicked = this.onOurMissionLinkClicked.bind(this);
    this.onOurApproachLinkClicked = this.onOurApproachLinkClicked.bind(this);
    this.onOurCommitmentLinkClicked =
      this.onOurCommitmentLinkClicked.bind(this);
    this.onWhatWeFundLinkClicked = this.onWhatWeFundLinkClicked.bind(this);
    this.onContactUsLinkClicked = this.onContactUsLinkClicked.bind(this);
    this.onVolunteerLinkClicked = this.onVolunteerLinkClicked.bind(this);
    this.onOtherWaysToHelpLinkClicked =
      this.onOtherWaysToHelpLinkClicked.bind(this);
    this.onSupportersLinkClicked = this.onSupportersLinkClicked.bind(this);
    this.onEventsCalendarLinkClicked =
      this.onEventsCalendarLinkClicked.bind(this);
    this.onHostAnEventLinkClicked = this.onHostAnEventLinkClicked.bind(this);
    this.onScholarshipsListLinkClicked =
      this.onScholarshipsListLinkClicked.bind(this);
    this.onBrightChildScholarshipLearnMoreButtonClicked =
      this.onBrightChildScholarshipLearnMoreButtonClicked.bind(this);
    this.onBrightChildScholarshipApplyNowButtonClicked =
      this.onBrightChildScholarshipApplyNowButtonClicked.bind(this);
    this.onPromoteAScholarshipLinkClicked =
      this.onPromoteAScholarshipLinkClicked.bind(this);
    this.onDonateMoneyLinkClicked = this.onDonateMoneyLinkClicked.bind(this);
    this.onDonateGoodsAndServicesLinkClicked =
      this.onDonateGoodsAndServicesLinkClicked.bind(this);
    this.onDonationAmountTextInputChanged =
      this.onDonationAmountTextInputChanged.bind(this);
    this.onCoverTransactionCostsCheckboxChanged =
      this.onCoverTransactionCostsCheckboxChanged.bind(this);
    this.onDonorFirstNameTextInputChanged =
      this.onDonorFirstNameTextInputChanged.bind(this);
    this.onDonorLastNameTextInputChanged =
      this.onDonorLastNameTextInputChanged.bind(this);
    this.onDonorPhoneNumberTextInputChanged =
      this.onDonorPhoneNumberTextInputChanged.bind(this);
    this.onDonorEmailAddressTextInputChanged =
      this.onDonorEmailAddressTextInputChanged.bind(this);
    this.onDonorMailingAddressTextInputChanged =
      this.onDonorMailingAddressTextInputChanged.bind(this);
    this.onCreditCardNumberTextInputChanged =
      this.onCreditCardNumberTextInputChanged.bind(this);
    this.onCreditCardExpirationDateTextInputChanged =
      this.onCreditCardExpirationDateTextInputChanged.bind(this);
    this.onCvvTextInputChanged = this.onCvvTextInputChanged.bind(this);
    this.onCompleteDonationButtonClicked =
      this.onCompleteDonationButtonClicked.bind(this);
    this.onSubscribeToMailListLinkClicked =
      this.onSubscribeToMailListLinkClicked.bind(this);
    this.onHashChanged = this.onHashChanged.bind(this);
    this.onFacebookLinkClicked = this.onFacebookLinkClicked.bind(this);
    this.onXLinkClicked = this.onXLinkClicked.bind(this);
    this.onInstagramLinkClicked = this.onInstagramLinkClicked.bind(this);
    this.onYoutubeLinkClicked = this.onYoutubeLinkClicked.bind(this);
    this.onLinkedinLinkClicked = this.onLinkedinLinkClicked.bind(this);
    this.updateDateDotNowAndScheduleNextUpdate =
      this.updateDateDotNowAndScheduleNextUpdate.bind(this);
    this.scheduleNextDateDotNowUpdate =
      this.scheduleNextDateDotNowUpdate.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener("hashchange", this.onHashChanged);
    this.shouldUpdateDateDotNow = true;
    this.scheduleNextDateDotNowUpdate();
  }

  updateDateDotNowAndScheduleNextUpdate(): void {
    if (this.shouldUpdateDateDotNow) {
      this.setState(
        { dateDotNow: Date.now() },
        this.scheduleNextDateDotNowUpdate
      );
    }
  }

  scheduleNextDateDotNowUpdate(): void {
    setTimeout(this.updateDateDotNowAndScheduleNextUpdate, 1000);
  }

  componentWillUnmount(): void {
    window.removeEventListener("hashchange", this.onHashChanged);
    this.shouldUpdateDateDotNow = false;
  }

  onHashChanged(): void {
    this.setState({ hash: window.location.hash }, resetScroll);
  }

  render(): React.ReactElement {
    const normalizedHash = normalizeUrlHash(this.state.hash);

    if (normalizedHash === URL_HASHES.home) {
      return this.renderHomePage();
    }

    if (normalizedHash === URL_HASHES.ourMission) {
      return this.renderOurMissionPage();
    }

    if (normalizedHash === URL_HASHES.ourApproach) {
      return this.renderOurApproachPage();
    }

    if (normalizedHash === URL_HASHES.ourCommitment) {
      return this.renderOurCommitmentPage();
    }

    if (normalizedHash === URL_HASHES.whatWeFund) {
      return this.renderWhatWeFundPage();
    }

    if (normalizedHash === URL_HASHES.volunteer) {
      return this.renderVolunteerPage();
    }

    if (normalizedHash === URL_HASHES.otherWaysToHelp) {
      return this.renderOtherWaysToHelpPage();
    }

    if (normalizedHash === URL_HASHES.eventsCalendar) {
      return this.renderEventsCalendarPage();
    }

    if (normalizedHash === URL_HASHES.hostAnEvent) {
      return this.renderHostAnEventPage();
    }

    if (normalizedHash === URL_HASHES.scholarshipsList) {
      return this.renderScholarshipsListPage();
    }

    if (normalizedHash === URL_HASHES.brightChildScholarshipDetails) {
      return this.renderBrightChildScholarshipDetailsPage();
    }

    if (normalizedHash === URL_HASHES.promoteAScholarship) {
      return this.renderPromoteAScholarshipPage();
    }

    if (normalizedHash === URL_HASHES.donateMoney) {
      return this.renderDonateMoneyPage();
    }

    if (normalizedHash === URL_HASHES.donateGoodsAndServices) {
      return this.renderDonateGoodsAndServicesPage();
    }

    return this.render404Page();
  }

  renderHomePage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body">
          <div className="FullWidthImageContainer">
            <img
              src={images.some_person_coding_cropped}
              className="FullWidthImage"
              alt=""
            />

            <div className="ImageCenterCaptionContainer">
              <p className="ImageCenterCaption">
                We believe in computer literacy education for
              </p>
              <p className="ImageCenterCaption ImageCenterCaption--large">
                ALL
              </p>
            </div>
          </div>

          <h2 className="Body__Heading Body__Heading--largeMargin">
            We support computer literacy education for everyone.
          </h2>

          <p className="Body__Paragraph Body__Paragraph--missionStatement">
            At the Accessible Computer Literacy Education Foundation (ACLEF),
            our mission is to democratize access to high-quality computer
            literacy education for children across the world. We believe that
            every child, regardless of their financial background, should have
            the opportunity to learn, grow, and succeed in the ever-evolving
            digital world. By providing scholarships and resources, we aim to
            eliminate financial barriers and empower the next generation of
            innovators, thinkers, and leaders. Our commitment is to foster an
            inclusive and supportive environment where young minds can thrive
            and build a brighter future through technology.
          </p>

          <div className="CallToActionGalleryContainer">
            <table className="CallToActionGallery">
              <tbody>
                <tr>
                  <td className="CallToActionPanelContainer">
                    <div className="CallToActionPanel">
                      <div className="CallToActionPanel__Corset">
                        <img
                          src={images.children_with_robots_cropped}
                          className="CallToActionPanel__Image"
                          alt=""
                        />
                        <h2 className="CallToActionPanel__Heading">
                          Support our work
                        </h2>
                        <p className="CallToActionPanel__Description">
                          We rely on donations to provide scholarships for
                          underprivileged and underrepresented children to
                          access quality computer literacy education. Please
                          consider donating to help us empower the next
                          generation of tech innovators.
                        </p>
                      </div>
                    </div>
                  </td>

                  <td className="CallToActionPanelContainer">
                    <div className="CallToActionPanel">
                      <div className="CallToActionPanel__Corset">
                        <img
                          src={images.scratch_with_makey_makey}
                          className="CallToActionPanel__Image"
                          alt=""
                        />

                        <h2 className="CallToActionPanel__Heading">
                          Subscribe
                        </h2>

                        <p className="CallToActionPanel__Description">
                          Subscribe to our email list to receive news and
                          updates about events, resources, stories, and more
                          from ACLEF.
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <button
                      className="Button Button--callToAction"
                      onClick={this.onDonateMoneyLinkClicked}
                    >
                      Donate
                    </button>
                  </td>
                  <td>
                    <button
                      className="Button Button--callToAction"
                      onClick={this.onSubscribeToMailListLinkClicked}
                    >
                      Subscribe
                    </button>
                  </td>
                </tr>

                <tr>
                  <td className="CallToActionPanelContainer">
                    <div className="CallToActionPanel">
                      <div className="CallToActionPanel__Corset">
                        <img
                          src={images.women_coding}
                          className="CallToActionPanel__Image"
                          alt=""
                        />
                        <h2 className="CallToActionPanel__Heading">
                          Get involved
                        </h2>
                        <p className="CallToActionPanel__Description">
                          Join us in making a difference! Whether you volunteer
                          your time, fundraise, spread the word, or collaborate
                          with us, your involvement helps children across the
                          world access quality computer literacy education. Get
                          involved today and help shape the future of tech
                          innovators.
                        </p>
                      </div>
                    </div>
                  </td>

                  <td className="CallToActionPanelContainer">
                    <div className="CallToActionPanel">
                      <div className="CallToActionPanel__Corset">
                        <img
                          src={images.logo_cropped}
                          className="CallToActionPanel__Image"
                          alt=""
                        />

                        <h2 className="CallToActionPanel__Heading">
                          Learn about ACLEF
                        </h2>

                        <p className="CallToActionPanel__Description">
                          Learn about ACLEF and our mission to make computer
                          literacy education accessible to all.
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <button
                      className="Button Button--callToAction"
                      onClick={this.onVolunteerLinkClicked}
                    >
                      Get Involved
                    </button>
                  </td>
                  <td>
                    <button
                      className="Button Button--callToAction"
                      onClick={this.onOurMissionLinkClicked}
                    >
                      Learn More
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderOurMissionPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderAboutPageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Our Mission</h2>

            <p>
              At the Accessible Computer Literacy Education Foundation (ACLEF),
              our mission is to break down the barriers to quality computer
              literacy education for children and young adults from
              underprivileged backgrounds. We believe that every child,
              regardless of their socio-economic status, should have the
              opportunity to explore, learn, and excel in the ever-evolving
              digital world. Through our programs and initiatives, we aim to
              create a more inclusive and equitable tech landscape.
            </p>

            <p>
              We provide scholarships and resources to ensure that financial
              constraints do not hinder talented and passionate students from
              accessing top-notch computer literacy education. Our scholarships
              cover tuition, materials, and access to cutting-edge technology,
              allowing students to focus on their learning and development. By
              investing in these young minds, we are not only supporting their
              personal growth but also fostering the next generation of
              innovators, problem-solvers, and leaders in the tech industry.
            </p>

            <p>
              Beyond financial support, ACLEF is dedicated to building a
              supportive and nurturing community. We partner with schools,
              educators, and tech organizations to offer mentorship, workshops,
              and real-world learning experiences. Our goal is to inspire and
              equip students with the skills and confidence they need to thrive
              in a digital world. We are committed to creating pathways for
              success and ensuring that the benefits of technological
              advancements are accessible to all.
            </p>

            <p>
              Join us in our mission to transform lives through education and
              technology. Together, we can make a lasting impact and pave the
              way for a brighter, more inclusive future. Whether you contribute
              through donations, volunteer your time, or spread the word about
              our cause, your involvement is crucial in helping us achieve our
              vision. Let's empower the next generation and create a world where
              everyone has the chance to succeed.
            </p>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderOurApproachPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderAboutPageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Our Approach</h2>

            <p>
              Our approach is centered on creating an inclusive and supportive
              environment where all children and young adults have the
              opportunity to develop essential computer literacy and computer
              science skills. We understand that in today's digital age,
              proficiency in these areas is crucial for personal and
              professional success. Therefore, we have designed our programs to
              be comprehensive, accessible, and tailored to meet the diverse
              needs of our students.
            </p>

            <p>
              Our programs are built on a foundation of accessibility and
              equity. We provide scholarships to underprivileged students,
              ensuring that financial constraints do not stand in the way of
              accessing quality education. These scholarships cover tuition,
              learning materials, and access to modern technology, enabling
              students to fully engage in their studies without the burden of
              financial stress. By removing these barriers, we empower students
              to focus on their learning and unlock their full potential.
            </p>

            <p>
              Mentorship is a key component of our approach. We partner with
              experienced educators, industry professionals, and tech
              organizations to provide guidance and support to our students.
              Through mentorship, we aim to inspire and motivate our students,
              helping them navigate their educational journey and explore
              various career paths in the tech industry. Our mentors serve as
              role models, offering valuable insights and fostering a sense of
              community and belonging.
            </p>

            <p>
              At ACLEF, we are committed to continuous improvement and
              innovation. We regularly update our programs to reflect the latest
              advancements in technology and educational practices. We also
              actively seek feedback from our students, parents, and partners to
              ensure that our offerings remain relevant and effective. Our goal
              is to equip our students with the skills and confidence they need
              to thrive in a rapidly changing digital world.
            </p>

            <p>
              Join us in our mission to make computer literacy and computer
              science education accessible to all. Together, we can create a
              brighter future where every child has the tools and opportunities
              to succeed.
            </p>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderOurCommitmentPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderAboutPageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Our DEI Commitment Statement</h2>

            <p>
              At the Accessible Computer Literacy Education Foundation (ACLEF),
              diversity, equity, and inclusion (DEI) are at the heart of
              everything we do. We are committed to fostering an environment
              where every individual, regardless of their background, has the
              opportunity to learn, grow, and succeed. Our DEI commitment is not
              just about representation but about creating a culture of
              belonging, respect, and empowerment for all our students, staff,
              and community members.
            </p>

            <p>
              Diversity: We celebrate and embrace the rich tapestry of
              experiences, perspectives, and identities that make up our
              community. We recognize that diversity is a strength that enhances
              our learning environment and drives innovation. Our programs are
              designed to be inclusive of all students, with a particular focus
              on reaching those from underrepresented and marginalized groups.
              By providing diverse role models, materials, and opportunities, we
              aim to inspire all students to see themselves as capable and
              valuable contributors to the tech industry.
            </p>

            <p>
              Equity: We are dedicated to ensuring that every student has access
              to the resources, support, and opportunities they need to succeed.
              Equity is about leveling the playing field and addressing systemic
              barriers that have historically excluded certain groups from
              education and career opportunities in computer science and
              technology. Our scholarship programs, mentorship initiatives, and
              community partnerships are all geared towards creating equitable
              pathways for success. We continuously assess and adapt our
              strategies to meet the evolving needs of our students and to
              ensure that no one is left behind.
            </p>

            <p>
              Inclusion: Inclusion means creating a welcoming and supportive
              environment where every individual feels valued and respected. At
              ACLEF, we strive to cultivate a culture where differences are not
              just acknowledged but celebrated. We encourage open dialogue,
              active listening, and mutual respect among all members of our
              community. Our inclusive practices extend to our teaching methods,
              curriculum design, and organizational policies, ensuring that
              everyone can participate fully and authentically.
            </p>

            <p>
              <span className="Bold">
                Our DEI commitment is a continuous journey. We are dedicated to
                ongoing learning, reflection, and improvement to ensure that our
                practices align with our values.
              </span>
            </p>

            <p>
              Join us in our commitment to diversity, equity, and inclusion.
              Together, we can make a meaningful impact and empower the next
              generation of tech leaders to create a more just and inclusive
              society.
            </p>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderWhatWeFundPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderAboutPageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">What We Fund</h2>

            <p>
              At the Accessible Computer Literacy Education Foundation (ACLEF),
              our funding priorities are aligned with our mission to provide
              comprehensive and inclusive computer literacy and computer science
              education to underprivileged children and young adults. Our goal
              is to break down financial barriers and create pathways to success
              in the digital age. Here are the key areas we focus on funding:
            </p>

            <p>
              Scholarships: We provide scholarships to cover the cost of tuition
              for students enrolling in computer literacy and computer science
              programs. These scholarships are available to children and young
              adults from low-income families, ensuring that financial
              constraints do not hinder their access to quality education. By
              funding tuition, we enable students to focus on their studies and
              achieve their academic goals.
            </p>

            <p>
              Educational Materials and Resources: To ensure that all students
              have the tools they need to succeed, we fund the purchase of
              textbooks, software, and other educational materials. We also
              provide access to cutting-edge technology, including laptops,
              tablets, and coding kits. These resources are essential for
              hands-on learning and help students develop practical skills that
              are crucial for their future careers.
            </p>

            <p>
              Workshops and Camps: We fund a variety of workshops and coding
              camps designed to engage students in interactive and practical
              learning experiences. These programs cover a wide range of topics,
              from basic computer literacy to advanced programming and robotics.
              By participating in these workshops and camps, students gain
              valuable knowledge and skills, as well as the confidence to pursue
              further studies and careers in technology.
            </p>

            <p>
              Mentorship Programs: Our mentorship programs connect students with
              experienced educators, industry professionals, and tech experts
              who provide guidance, support, and inspiration. We fund these
              programs to ensure that every student has access to positive role
              models and mentors who can help them navigate their educational
              journey and explore career opportunities. Mentorship is a key
              component of our approach to fostering a supportive and empowering
              learning environment.
            </p>

            <p>
              Community Partnerships: We believe in the power of collaboration
              and community. That’s why we fund partnerships with schools,
              community organizations, and tech companies to create a network of
              support for our students. These partnerships enable us to offer a
              broader range of programs and resources, as well as to reach more
              students in need. Together, we can create a more inclusive and
              equitable educational ecosystem.
            </p>

            <p>
              Special Projects and Initiatives: In addition to our core
              programs, we fund special projects and initiatives that align with
              our mission and values. These may include research projects, pilot
              programs, and advocacy efforts aimed at advancing computer
              literacy and computer science education. By supporting innovative
              and impactful initiatives, we strive to drive positive change and
              create a brighter future for all students.
            </p>

            <p>
              Through our funding efforts, ACLEF is dedicated to making a
              lasting impact on the lives of underprivileged students. We are
              committed to providing the financial support and resources needed
              to empower the next generation of tech leaders. Join us in our
              mission to create a world where every child has the opportunity to
              succeed in the digital age.
            </p>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderVolunteerPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderContributePageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Volunteer</h2>

            <p>
              At the Accessible Computer Literacy Education Foundation (ACLEF),
              our mission to provide quality computer literacy and computer
              science education to underprivileged children relies on the
              dedication and passion of our volunteers. We are currently seeking
              committed individuals to join our team in various long-term and
              short-term positions. If you are passionate about education,
              technology, and making a difference, we invite you to explore the
              opportunities below and consider becoming a part of our mission.
            </p>

            <h3>Long-term Volunteer Positions</h3>

            <section className="VolunteerPosition">
              <h4>Webpage Designer</h4>
              <p>
                We are looking for a talented webpage designer to help us create
                an engaging, user-friendly website. The ideal candidate will
                have experience in web design, a strong understanding of user
                experience (UX) principles, and the ability to bring our mission
                to life through a compelling online presence. Your work will
                ensure that our website effectively communicates our programs,
                events, and impact, making it easy for visitors to learn about
                and support our cause.
              </p>
            </section>

            <section className="VolunteerPosition">
              <h4>Social Media Manager</h4>
              <p>
                Are you skilled in social media strategy and content creation?
                We need a social media manager to help us grow our online
                community and increase awareness of our mission. This role
                involves managing our social media accounts, creating and
                scheduling posts, and engaging with our audience. Your efforts
                will help us reach more supporters, volunteers, and students,
                amplifying our message and impact.
              </p>
            </section>

            <section className="VolunteerPosition">
              <h4>Events Manager</h4>
              <p>
                We are seeking an organized and creative events manager to plan
                and execute our workshops, coding camps, and special events. The
                ideal candidate will have experience in event planning,
                excellent communication skills, and a passion for education. You
                will be responsible for coordinating logistics, managing
                volunteers, and ensuring that each event runs smoothly and
                successfully, providing valuable learning experiences for our
                students.
              </p>
            </section>

            <section className="VolunteerPosition">
              <h4>Communications Manager</h4>
              <p>
                As a communications manager, you will play a crucial role in
                shaping and sharing our story. We are looking for someone with
                strong writing and communication skills to manage our outreach
                efforts, including newsletters, press releases, and other
                communications. Your work will help us build relationships with
                supporters, partners, and the media, spreading the word about
                our mission and achievements.
              </p>
            </section>

            <h3>Short-term Volunteer Opportunities</h3>

            <section className="VolunteerPosition">
              <h4>Donation Specialists</h4>
              <p>
                We are in need of individuals who can help us secure donations
                and partnerships with companies and other organizations. This
                role involves identifying potential donors, crafting compelling
                proposals, and building relationships with key stakeholders.
                Even a short-term commitment can provide the financial support
                necessary for us to continue offering scholarships, resources,
                and programs to underprivileged students.
              </p>
            </section>

            <section className="VolunteerPosition">
              <h4>Workshop Hosts and Mentors</h4>
              <p>
                Do you have expertise in computer science or digital literacy?
                We are looking for volunteers to host workshops and mentor
                students. As a workshop host, you will lead educational sessions
                and provide hands-on learning experiences. As a mentor, you will
                offer guidance, support, and inspiration to students, helping
                them navigate their educational journey and develop their
                skills. Your involvement, even on a short-term basis, will
                directly impact the lives of our students, empowering them to
                succeed in the digital world.
              </p>
            </section>

            <p className="LargeTopPadding">
              At ACLEF, volunteers are the backbone of our organization. By
              contributing your time and skills, you will help us create a more
              inclusive and equitable future for all students. If you are
              interested in any of these positions, please contact us using the
              button below. Together, we can make a lasting impact and empower
              the next generation of tech leaders.
            </p>

            <button className="Button" onClick={this.onContactUsLinkClicked}>
              Contact Us
            </button>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderOtherWaysToHelpPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderContributePageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Other Ways to Help</h2>
            <p>
              At the Accessible Computer Literacy Education Foundation (ACLEF),
              we understand that not everyone is in a position to make a
              financial contribution or commit to volunteering. However, there
              are numerous other ways you can support our mission and help us
              make a significant impact on the lives of underprivileged
              students. Your support, in any form, is invaluable to us and can
              contribute to our efforts to provide quality computer literacy and
              computer science education.
            </p>
            <h3>Spread the Word</h3>
            <p>
              One of the most powerful ways to support ACLEF is by spreading the
              word about our mission and programs. Share our story, initiatives,
              and successes on social media platforms like Facebook, Twitter,
              Instagram, and LinkedIn. By raising awareness, you can help us
              reach more potential supporters, volunteers, and students.
              Encourage your friends, family, and colleagues to follow us on
              social media and to share our posts. Every like, share, and
              comment helps amplify our message and expand our reach.
            </p>
            <h3>Advocate for Us</h3>
            Become an advocate for ACLEF within your community. Talk to local
            schools, community centers, and organizations about our programs and
            how they can benefit students in need. Help us establish connections
            with educational institutions and community groups that can support
            our mission or refer students to our programs. Your advocacy can
            open doors to new partnerships and opportunities that can enhance
            our impact.
            <h3>Secure In-Kind Donations</h3>
            <p>
              Consider leveraging your professional network to secure in-kind
              donations from companies or organizations. Many businesses are
              willing to donate goods and services that can support our
              programs. This might include computers, software, office supplies,
              or even venues for our events and workshops. If your employer has
              a corporate social responsibility program, suggest ACLEF as a
              recipient for in-kind donations. Your efforts can provide us with
              essential resources without any financial outlay.
            </p>
            <h3>Host a Fundraiser</h3>
            <p>
              Organize a fundraiser to support ACLEF in your community. This
              could be a bake sale, a car wash, a charity run, or any other
              event that you think would be successful in your area. Fundraisers
              not only raise money but also increase awareness of our mission.
              We can provide you with materials and support to help make your
              event a success. Gather your friends, family, and neighbors to
              participate and contribute to a cause that makes a real
              difference.
            </p>
            <h3>Offer Your Skills</h3>
            <p>
              If you have a specific skill or expertise, consider offering your
              services pro bono. Whether you are a graphic designer, a writer, a
              photographer, or have another talent, your skills can be
              incredibly valuable to us. You could help create promotional
              materials, write content for our website, take photos at our
              events, or provide other professional services that support our
              operations and outreach efforts.
            </p>
            <h3>Participate in Our Events</h3>
            <p>
              Join us at our events and workshops to show your support. Your
              presence at our events can help create a vibrant and encouraging
              atmosphere for our students and volunteers. Participate in our
              coding workshops, attend our community meetings, or simply come
              along to cheer on our students at their showcases. Your
              involvement helps build a strong, supportive community around our
              mission.
            </p>
            <p>
              There are countless ways to contribute to ACLEF beyond monetary
              donations and volunteering. Every effort, no matter how small it
              may seem, helps us move closer to our goal of making computer
              literacy and computer science education accessible to all. Thank
              you for considering these alternative ways to support our mission.
              Together, we can create a brighter future for our students and
              empower the next generation of tech leaders.
            </p>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderEventsCalendarPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderEventsPageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Events</h2>
            <section className="Event">
              <h3>
                <a
                  href="https://openslf.github.io/signup-wizard/events/b829296e958a6/view"
                  target="_blank"
                  rel="noreferrer"
                  className="EventLink"
                >
                  Empowering Future Coders: Intro to Computer Science
                </a>
              </h3>
              <p>August 31, 2024 ・ 10:00 AM - 11:00 AM</p>
              <p>
                Join us for an introductory workshop designed for beginners,
                where we will explore the basics of computer science. Learn
                fundamental concepts through interactive lessons and hands-on
                activities. This event is perfect for students new to the world
                of coding and computer literacy.
              </p>
            </section>
            <section className="Event">
              <h3>
                <a
                  href="https://openslf.github.io/signup-wizard/events/99cd9a76505b3/view"
                  target="_blank"
                  rel="noreferrer"
                  className="EventLink"
                >
                  Coding for Change: Using Tech to Solve Real-World Problems
                </a>
              </h3>
              <p>September 10, 2024 ・ 11:00 AM - 12:00 PM</p>
              <p>
                Discover how coding can be used to address some of the world's
                most pressing issues. Participants will work in teams to develop
                digital solutions for environmental, social, and community
                challenges. This workshop will encourage critical thinking and
                innovation.
              </p>
            </section>
            <section className="Event">
              <h3>
                <a
                  href="https://openslf.github.io/signup-wizard/events/a9ae0b109551b/view"
                  target="_blank"
                  rel="noreferrer"
                  className="EventLink"
                >
                  Digital Literacy Bootcamp: Essential Skills for the Modern
                  World
                </a>
              </h3>
              <p>September 21, 2024 ・ 10:00 AM - 11:00 AM</p>
              <p>
                In this intensive bootcamp, students will gain essential digital
                literacy skills, from navigating the internet safely to
                understanding basic programming. This event aims to equip
                students with the knowledge they need to thrive in a
                digital-first world.
              </p>
            </section>
            <section className="Event">
              <h3>
                <a
                  href="https://openslf.github.io/signup-wizard/events/31be1203bb36f/view"
                  target="_blank"
                  rel="noreferrer"
                  className="EventLink"
                >
                  Girls in Tech: Inspiring the Next Generation
                </a>
              </h3>
              <p>September 27, 2024 ・ 2:00 PM - 3:00 PM</p>
              <p>
                A special event focused on empowering young girls to pursue
                careers in technology. Through mentorship, workshops, and
                inspirational talks, we aim to close the gender gap in tech and
                encourage girls to explore their potential in computer science
                and digital literacy.
              </p>
            </section>
            <section className="Event">
              <h3>
                <a
                  href="https://openslf.github.io/signup-wizard/events/0fa2e12f65687/view"
                  target="_blank"
                  rel="noreferrer"
                  className="EventLink"
                >
                  Family Coding Night: Bringing Tech Education Home
                </a>
              </h3>
              <p>October 4, 2024 ・ 8:00 PM - 9:00 PM</p>
              <p>
                An evening of fun and learning for the whole family. Parents and
                children will work together on coding projects and interactive
                tech activities. This event is designed to foster a supportive
                environment for learning and collaboration at home.
              </p>
            </section>
            <section className="Event">
              <h3>
                <a
                  href="https://openslf.github.io/signup-wizard/events/b73b97124ef66/view"
                  target="_blank"
                  rel="noreferrer"
                  className="EventLink"
                >
                  Accessible Tech: Designing for Inclusion
                </a>
              </h3>
              <p>October 12, 2024 ・ 10:00 AM - 11:00 AM</p>
              <p>
                Join us for a workshop on designing technology that is
                accessible to everyone, including individuals with disabilities.
                Learn about best practices in inclusive design and create your
                own accessible tech solutions. This event is ideal for aspiring
                developers and designers committed to equity in technology.
              </p>
            </section>
            <section className="Event">
              <h3>
                <a
                  href="https://openslf.github.io/signup-wizard/events/ec0f40a92cd5e/view"
                  target="_blank"
                  rel="noreferrer"
                  className="EventLink"
                >
                  Teacher Training: Integrating Computer Science into the
                  Classroom
                </a>
              </h3>
              <p>October 20, 2024 ・ 3:00 PM - 4:00 PM</p>
              <p>
                A professional development event for educators looking to
                incorporate computer science and digital literacy into their
                curriculum. Teachers will receive training on the latest tools
                and techniques, as well as resources to support their students'
                learning.
              </p>
            </section>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderHostAnEventPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderEventsPageNavSection()}

          <div className="SubNavigable__Content">
            <h2>Host an Event</h2>
            <p>
              Have an idea for an event to host? Tell us all about it by
              clicking the "Contact Us" button below! We are always eager to
              collaborate with those who share our vision of making computer
              literacy education accessible to all.
            </p>
            <button className="Button" onClick={this.onContactUsLinkClicked}>
              Contact Us
            </button>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderScholarshipsListPage(): React.ReactElement {
    const { dateDotNow } = this.state;
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderScholarshipsPageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Scholarships</h2>

            <Scholarship
              name="Bright Child Scholarship"
              deadline={SCHOLARSHIP_DEADLINES.brightChild}
              dateDotNow={dateDotNow}
              onLearnMoreButtonClicked={
                this.onBrightChildScholarshipLearnMoreButtonClicked
              }
            >
              <p>
                The Bright Child Scholarship offers financial assistance for
                tuition to online summer camps and after-school programs for
                children in grades 1-12. Open to students from all financial
                backgrounds, this scholarship provides an opportunity to enhance
                computer literacy and coding skills. Applications are due by
                July 26 (Pacific Time), and the process is quick and
                straightforward. Apply now to equip your child with the skills
                they need to thrive in the digital age!
              </p>
            </Scholarship>

            <Scholarship
              name="Ellie Ozeki Scholarship"
              deadline={SCHOLARSHIP_DEADLINES.ellieOzeki}
              dateDotNow={dateDotNow}
            >
              <p>
                The Ellie Ozeki Scholarship is dedicated to empowering girls in
                grades 6-12 who are passionate about computer science. This
                scholarship provides financial assistance for tuition to a list
                of accredited online courses, camps, and programs. Our goal is
                to support and inspire the next generation of female tech
                leaders, encouraging them to pursue their dreams and make a
                lasting impact in the tech industry. Applications are due by
                June 28 (Pacific Time).
              </p>
            </Scholarship>

            <Scholarship
              name="Digital Dreamers Scholarship"
              deadline={SCHOLARSHIP_DEADLINES.digitalDreamers}
              dateDotNow={dateDotNow}
            >
              <p>
                The Digital Dreamers Scholarship is available to students in
                grades 3-8, providing financial assistance for enrollment in
                engaging and interactive online coding camps and workshops. This
                scholarship focuses on nurturing a passion for technology and
                helping students turn their digital dreams into reality.
                Applications are due by June 28 (Pacific Time).
              </p>
            </Scholarship>

            <Scholarship
              name="Tech Trailblazers Scholarship"
              deadline={SCHOLARSHIP_DEADLINES.techTrailblazers}
              dateDotNow={dateDotNow}
            >
              <p>
                The Tech Trailblazers Scholarship is designed for high school
                students (grades 9-12) who are passionate about computer science
                and technology. This scholarship covers tuition for advanced
                online courses and specialized tech programs, empowering
                students to blaze their own trails in the tech world.
                Applications are due by February 16 (Pacific Time).
              </p>
            </Scholarship>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderBrightChildScholarshipDetailsPage(): React.ReactElement {
    if (this.state.dateDotNow > +SCHOLARSHIP_DEADLINES.brightChild) {
      return (
        <div className="App">
          {this.renderHeader()}

          <div className="Body Body--tightCenter">
            <div className="TightCenter__Content">
              <h2 className="Body__Heading">2024 Bright Child Scholarship</h2>

              <p className="MediumTopPadding MediumBottomPadding">
                Sorry, the deadline for the 2024 Bright Child Scholarship has
                passed. To equip your child with the skills they need to thrive
                in the digital age, please consider applying for other
                scholarships!
              </p>

              <button
                className="Button"
                onClick={this.onScholarshipsListLinkClicked}
              >
                View Other Scholarships
              </button>
            </div>
          </div>
        </div>
      );
    }

    const prettyPrintedDeadline =
      SCHOLARSHIP_DEADLINES.brightChild.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });

    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--tightCenter">
          <div className="TightCenter__Content">
            <h2 className="Body__Heading">2024 Bright Child Scholarship</h2>

            <p className="Scholarship__Deadline SmallBottomPadding">
              Application due {prettyPrintedDeadline}
            </p>

            <button
              className="Button"
              onClick={this.onBrightChildScholarshipApplyNowButtonClicked}
            >
              Apply Now
            </button>

            <p className="MediumTopPadding MediumBottomPadding">
              The Accessible Computer Literacy Education Foundation (ACLEF) is
              proud to announce the 2024 Bright Child Scholarship, designed to
              support children in grades 1-12 in their pursuit of computer
              literacy and coding skills. This scholarship provides financial
              assistance for tuition to online summer camps and after-school
              programs, offering an engaging and enriching educational
              experience.
            </p>
            <h3>Who Can Apply</h3>
            <p>
              The 2024 Bright Child Scholarship is open to all children
              currently enrolled in grades 1-12. We believe in providing
              opportunities to students from all financial backgrounds, so we
              encourage everyone to apply, regardless of their economic
              situation.
            </p>
            <h3>Scholarship Benefits</h3>
            <p>
              Recipients of the Bright Child Scholarship will have their tuition
              partially or fully covered for high-quality online summer camps
              and after-school programs. These programs are designed to enhance
              digital literacy, introduce fundamental coding concepts, and
              foster a passion for technology and learning. Students will have
              access to interactive lessons, hands-on projects, and a supportive
              learning environment, all from the comfort of their home. The list
              of eligible programs will be provided to scholarship recipients
              upon selection.
            </p>
            <h3>Application Process</h3>
            <p>
              Applying for the 2024 Bright Child Scholarship is simple and
              straightforward. No extensive documentation or financial
              information is required, making the process quick and accessible.
            </p>
            <p>
              Simply click the blue "Apply Now" button at the top of this page,
              and fill out the form. You can complete the required fields in
              under one minute.
            </p>
            <h3>Important Dates</h3>
            <p>
              The application deadline is{" "}
              <span className="Bold">July 26 11:59 PM (Pacific Time)</span>. We
              encourage students, or their parents, to apply as soon as possible
              to secure their spot in our programs. Applications received after
              the deadline will not be considered, so please make sure to submit
              your application on time.
            </p>
            <p className="MediumTopPadding">
              We are excited to offer the 2024 Bright Child Scholarship and look
              forward to helping your child embark on a journey of discovery and
              learning in the field of computer literacy and coding. Apply today
              to give your child the skills they need to excel in their future!
            </p>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderPromoteAScholarshipPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderScholarshipsPageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Promote a Scholarship</h2>
            At the Accessible Computer Literacy Education Foundation (ACLEF), we
            are committed to providing students with opportunities to excel in
            computer literacy and computer science. One of the most impactful
            ways to contribute to this mission is by promoting a scholarship. If
            you have created a scholarship and are looking to reach more
            students, we are here to help you raise awareness and ensure that
            deserving students can benefit from your generosity.
            <h3>Why Promote a Scholarship with ACLEF?</h3>
            <p>
              Promoting your scholarship through ACLEF connects you with a
              network of dedicated educators, students, and families who share a
              passion for digital education. Our extensive outreach channels,
              including our website, social media platforms, and community
              partnerships, enable us to effectively spread the word about your
              scholarship. By collaborating with us, you can reach a broader
              audience and ensure that your scholarship finds its way to the
              students who need it the most.
            </p>
            <h3>How We Can Help</h3>
            <p>
              <span className="Bold">Website Listing:</span> We will prominently
              feature your scholarship on our Scholarships List, providing a
              detailed description, application requirements, and deadlines.
              This ensures that interested students and their families can
              easily find and access information about your scholarship.
            </p>
            <p>
              <span className="Bold">Social Media Campaigns:</span> Our team
              will create and share engaging social media posts to promote your
              scholarship across our platforms. We will highlight the benefits,
              eligibility criteria, and application process, encouraging our
              followers to apply and share the information within their
              networks.
            </p>
            <p>
              <span className="Bold">Email Newsletters:</span> We will include
              information about your scholarship in our regular email
              newsletters, which reach a wide audience of students, parents,
              educators, and supporters. This targeted approach ensures that
              your scholarship opportunity is seen by those most likely to
              apply.
            </p>
            <p>
              <span className="Bold">Community Outreach:</span> Our established
              relationships with schools, community centers, and educational
              organizations allow us to promote your scholarship directly to
              students and educators. We can facilitate workshops, webinars, or
              information sessions to further raise awareness and answer any
              questions potential applicants might have.
            </p>
            <h3>Get Started</h3>
            <p>
              Promoting a scholarship through ACLEF is a seamless process.
              Simply provide us with the details of your scholarship, including
              eligibility criteria, application process, deadlines, and any
              other pertinent information. Our team will work with you to create
              a comprehensive promotion plan tailored to your scholarship's
              unique objectives.
            </p>
            <p>
              By promoting your scholarship through ACLEF, you are not only
              giving students a valuable opportunity but also contributing to
              our shared mission of making computer literacy and computer
              science education accessible to all. Together, we can inspire and
              empower the next generation of tech leaders.
            </p>
            <p>
              If you are ready to promote your scholarship with us, please{" "}
              <span className="Link" onClick={this.onContactUsLinkClicked}>
                contact our team
              </span>
              . We look forward to partnering with you to make a lasting impact
              on the lives of students.
            </p>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderDonateMoneyPage(): React.ReactElement {
    const { donationForm } = this.state;
    const parsedDonationAmount = Number.parseFloat(donationForm.donationAmount);
    const isValidDonationAmount =
      !Number.isNaN(parsedDonationAmount) && parsedDonationAmount > 0;
    const isDonateButtonDisabled = !isValidDonationAmount;
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderDonatePageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Donate Money</h2>

            <p>
              At the Accessible Computer Literacy Education Foundation (ACLEF),
              your generosity fuels our mission to make computer literacy and
              computer science education accessible to all students, regardless
              of their financial background. Every donation, no matter the size,
              helps us provide scholarships, support educational programs, and
              empower the next generation of tech leaders. Your contribution can
              make a real difference in the lives of underprivileged children,
              opening doors to opportunities and fostering a passion for
              technology and learning. Thank you for your support and for
              helping us create a brighter future for our students.
            </p>

            <div className="DonationForm SmallTopPadding">
              <section>
                <h3>Donation amount</h3>
                <div className="DonationForm__Field">
                  $
                  <input
                    className="DonationForm__Field__TextInput"
                    type="text"
                    placeholder="Enter donation amount"
                    value={donationForm.donationAmount}
                    onChange={this.onDonationAmountTextInputChanged}
                  />
                  USD
                </div>
                <div className="DonationForm__Field">
                  <span className="CheckboxAndLabelContainer">
                    <input
                      className="DonationForm__Field__Checkbox"
                      type="checkbox"
                      checked={donationForm.coverTransactionCosts}
                      onChange={this.onCoverTransactionCostsCheckboxChanged}
                    />
                    <label>
                      Cover transaction costs so we can receive the full
                      donation
                    </label>
                  </span>
                </div>
              </section>

              <section>
                <h3>Donor information</h3>
                <div className="DonationForm__Field">
                  <label>First Name</label>
                  <input
                    className="DonationForm__Field__TextInput"
                    type="text"
                    value={donationForm.firstName}
                    onChange={this.onDonorFirstNameTextInputChanged}
                  />
                </div>
                <div className="DonationForm__Field">
                  <label>Last Name</label>
                  <input
                    className="DonationForm__Field__TextInput"
                    type="text"
                    value={donationForm.lastName}
                    onChange={this.onDonorLastNameTextInputChanged}
                  />
                </div>
                <div className="DonationForm__Field">
                  <label>Phone number</label>
                  <input
                    className="DonationForm__Field__TextInput"
                    type="text"
                    value={donationForm.phoneNumber}
                    onChange={this.onDonorPhoneNumberTextInputChanged}
                  />
                </div>
                <div className="DonationForm__Field">
                  <label>Email address</label>
                  <input
                    className="DonationForm__Field__TextInput"
                    type="text"
                    value={donationForm.emailAddress}
                    onChange={this.onDonorEmailAddressTextInputChanged}
                  />
                </div>
                <div className="DonationForm__Field">
                  <label>Mailing Address</label>
                  <input
                    className="DonationForm__Field__TextInput"
                    type="text"
                    placeholder="12345 Rane Dr. San Jose, CA 95111, United States"
                    value={donationForm.mailingAddress}
                    onChange={this.onDonorMailingAddressTextInputChanged}
                  />
                </div>
              </section>

              <section>
                <h3>Credit card information</h3>
                <div className="DonationForm__Field">
                  <label>Credit card number</label>
                  <input
                    className="DonationForm__Field__TextInput"
                    type="text"
                    placeholder="XXXX XXXX XXXX XXXX"
                    value={donationForm.creditCardNumber}
                    onChange={this.onCreditCardNumberTextInputChanged}
                  />
                </div>
                <div className="DonationForm__Field">
                  <label>Expiration</label>
                  <input
                    className="DonationForm__Field__TextInput"
                    type="text"
                    placeholder="MM/YY"
                    value={donationForm.creditCardExpirationDate}
                    onChange={this.onCreditCardExpirationDateTextInputChanged}
                  />
                </div>
                <div className="DonationForm__Field">
                  <label>CVV</label>
                  <input
                    className="DonationForm__Field__TextInput"
                    type="text"
                    placeholder="XXX"
                    value={donationForm.cvv}
                    onChange={this.onCvvTextInputChanged}
                  />
                </div>
              </section>

              <section className="MediumTopPadding">
                <p>
                  When you click the button below, it will open a new tab in
                  your browser. A third-party payment processing company manages
                  this tab. Please do not close this tab until a receipt appears
                  on the screen.
                </p>
                <button
                  className={
                    "Button" + (isDonateButtonDisabled ? " HalfOpacity" : "")
                  }
                  disabled={isDonateButtonDisabled}
                  onClick={this.onCompleteDonationButtonClicked}
                >
                  Donate
                  {isValidDonationAmount ? " $" + parsedDonationAmount : ""}
                </button>
              </section>
            </div>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  renderDonateGoodsAndServicesPage(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body Body--subNavigable">
          {this.renderDonatePageNavSection()}

          <div className="SubNavigable__Content">
            <h2 className="Body__Heading">Donate Goods and Services</h2>
            <p>
              At the Accessible Computer Literacy Education Foundation (ACLEF),
              we understand that support comes in many forms. In addition to
              monetary donations, we greatly appreciate contributions of goods
              and services that can help us further our mission. Whether you are
              an individual, a business, or an organization, your generous
              donations can make a significant impact on the educational
              experiences of our students.
            </p>
            <h3>Why Donate Goods and Services?</h3>
            <p>
              Donating goods and services is a powerful way to support our
              efforts to provide high-quality computer literacy and computer
              science education to students from all backgrounds. Your
              contributions can directly enhance our programs, workshops, and
              scholarships, ensuring that our students have access to the
              resources they need to succeed. From providing technology
              equipment to offering professional services, every donation helps
              us create a more enriching and effective learning environment.
            </p>
            <h3>Types of Goods and Services We Accept</h3>
            <p>
              <span className="Bold">Technology Equipment:</span> We are always
              in need of computers, tablets, software licenses, and other
              technology-related equipment. These tools are essential for our
              students to gain hands-on experience and develop their digital
              skills. New or gently used items are welcome and will be put to
              good use in our programs.
            </p>
            <p>
              <span className="Bold">Educational Materials:</span> Books, online
              course subscriptions, and educational software can greatly enhance
              our curriculum. By donating these materials, you help us provide a
              comprehensive and diverse learning experience for our students.
            </p>
            <p>
              <span className="Bold">Professional Services:</span> Your
              expertise can be invaluable to our organization. We welcome
              donations of professional services such as website development,
              graphic design, marketing, legal advice, and more. These services
              help us operate more efficiently and reach a broader audience.
            </p>
            <p>
              <span className="Bold">Discounts and Gift Cards:</span> If you own
              a business or have connections with service providers, consider
              donating discounts or gift cards. These can be used as incentives
              for our volunteers, rewards for our students, or as support for
              our operational needs.
            </p>
            <h3>How to Donate</h3>
            <p>
              Donating goods and services to ACLEF is simple and
              straightforward. Please{" "}
              <span className="Link" onClick={this.onContactUsLinkClicked}>
                reach out to our team
              </span>{" "}
              with details about the items or services you wish to donate. We
              will coordinate with you to ensure that your contributions are
              received and utilized effectively.
            </p>
            <p className="LargeTopPadding">
              Your donation of goods and services is not just a gift to our
              organization; it is an investment in the future of our students.
              By providing the tools and support they need, you are helping to
              create a generation of digitally literate and tech-savvy
              individuals who can thrive in an increasingly digital world. Thank
              you for considering this impactful way to contribute to ACLEF.
              Together, we can make a lasting difference.
            </p>
          </div>
        </div>

        {this.renderFooter()}
      </div>
    );
  }

  render404Page(): React.ReactElement {
    return (
      <div className="App">
        {this.renderHeader()}

        <div className="Body--404">
          <p>Page not found.</p>
          <button className="Button" onClick={this.onHomeLinkClicked}>
            Home
          </button>
        </div>
      </div>
    );
  }

  renderHeader(): React.ReactElement {
    const normalizedHash = normalizeUrlHash(this.state.hash);
    return (
      <header className="Header">
        <div className="Header__LogoContainer" onClick={this.onHomeLinkClicked}>
          <div className="Header__LogoImageContainer">
            <img
              src={images.logo1024}
              className="Header__LogoImage"
              alt="logo"
            />
          </div>
          <h2 className="Header__Heading">ACLEF</h2>
        </div>

        <div className="NavBar">
          <span className="NavBar__TopLevelLinkContainer">
            <span
              className={
                "NavBar__TopLevelLink" +
                (normalizedHash === URL_HASHES.home
                  ? " NavBar__TopLevelLink--active"
                  : "")
              }
              onClick={this.onHomeLinkClicked}
            >
              Home
            </span>
          </span>
          <span className="NavBar__TopLevelLinkContainer">
            <span
              className={
                "NavBar__TopLevelLink" +
                (identityFunctionForTypesafeTypeCast<readonly string[]>(
                  URL_HASHES_WHERE_THE_ABOUT_NAV_LINK_IS_CONSIDERED_ACTIVE
                ).includes(normalizedHash)
                  ? " NavBar__TopLevelLink--active"
                  : "")
              }
              onClick={this.onOurMissionLinkClicked}
            >
              About
            </span>
          </span>
          <span className="NavBar__TopLevelLinkContainer">
            <span
              className={
                "NavBar__TopLevelLink" +
                (identityFunctionForTypesafeTypeCast<readonly string[]>(
                  URL_HASHES_WHERE_THE_CONTRIBUTE_NAV_LINK_IS_CONSIDERED_ACTIVE
                ).includes(normalizedHash)
                  ? " NavBar__TopLevelLink--active"
                  : "")
              }
              onClick={this.onVolunteerLinkClicked}
            >
              Contribute
            </span>
          </span>
          <span className="NavBar__TopLevelLinkContainer">
            <span
              className={
                "NavBar__TopLevelLink" +
                (normalizedHash === URL_HASHES.supporters
                  ? " NavBar__TopLevelLink--active"
                  : "")
              }
              onClick={this.onSupportersLinkClicked}
            >
              Supporters
            </span>
          </span>
          <span className="NavBar__TopLevelLinkContainer">
            <span
              className={
                "NavBar__TopLevelLink" +
                (identityFunctionForTypesafeTypeCast<readonly string[]>(
                  URL_HASHES_WHERE_THE_EVENTS_NAV_LINK_IS_CONSIDERED_ACTIVE
                ).includes(normalizedHash)
                  ? " NavBar__TopLevelLink--active"
                  : "")
              }
              onClick={this.onEventsCalendarLinkClicked}
            >
              Events
            </span>
          </span>
          <span className="NavBar__TopLevelLinkContainer">
            <span
              className={
                "NavBar__TopLevelLink" +
                (identityFunctionForTypesafeTypeCast<readonly string[]>(
                  URL_HASHES_WHERE_THE_SCHOLARSHIPS_NAV_LINK_IS_CONSIDERED_ACTIVE
                ).includes(normalizedHash)
                  ? " NavBar__TopLevelLink--active"
                  : "")
              }
              onClick={this.onScholarshipsListLinkClicked}
            >
              Scholarships
            </span>
          </span>
          <span className="NavBar__TopLevelLinkContainer">
            <button
              className={"Button"}
              onClick={this.onDonateMoneyLinkClicked}
            >
              Donate
            </button>
          </span>
        </div>
      </header>
    );
  }

  renderFooter(): React.ReactElement {
    return (
      <footer className="Footer">
        <div className="Footer__SocialMediaLinks">
          <socialMediaIcons.Facebook
            className="SocialMediaLink SocialMediaLink--nonFinal"
            onClick={this.onFacebookLinkClicked}
          />
          <socialMediaIcons.X
            className="SocialMediaLink SocialMediaLink--nonFinal"
            onClick={this.onXLinkClicked}
          />
          <socialMediaIcons.Instagram
            className="SocialMediaLink SocialMediaLink--nonFinal"
            onClick={this.onInstagramLinkClicked}
          />
          <socialMediaIcons.Youtube
            className="SocialMediaLink SocialMediaLink--nonFinal"
            onClick={this.onYoutubeLinkClicked}
          />
          <socialMediaIcons.Linkedin
            className="SocialMediaLink"
            onClick={this.onLinkedinLinkClicked}
          />
        </div>

        <div className="Footer__CopyrightNotice">
          © 2024 Accessible Computer Literacy Education Foundation
        </div>
      </footer>
    );
  }

  renderAboutPageNavSection(): React.ReactElement {
    const normalizedHash = normalizeUrlHash(this.state.hash);
    return (
      <div className="SubNavigable__Nav">
        <span className="SubNavigable__Nav__Heading">About</span>

        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.ourMission
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onOurMissionLinkClicked}
        >
          Our Mission
        </span>
        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.ourApproach
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onOurApproachLinkClicked}
        >
          Our Approach
        </span>
        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.ourCommitment
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onOurCommitmentLinkClicked}
        >
          Our Commitment
        </span>
        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.volunteer
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onVolunteerLinkClicked}
        >
          Contribute
        </span>
        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.whatWeFund
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onWhatWeFundLinkClicked}
        >
          What We Fund
        </span>
        <span
          className={"SubNavigable__Nav__Link"}
          onClick={this.onContactUsLinkClicked}
        >
          Contact Us
        </span>
      </div>
    );
  }

  renderContributePageNavSection(): React.ReactElement {
    const normalizedHash = normalizeUrlHash(this.state.hash);
    return (
      <div className="SubNavigable__Nav">
        <span className="SubNavigable__Nav__Heading">About</span>

        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.volunteer
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onVolunteerLinkClicked}
        >
          Volunteer
        </span>
        <span
          className={"SubNavigable__Nav__Link"}
          onClick={this.onDonateMoneyLinkClicked}
        >
          Donate
        </span>
        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.otherWaysToHelp
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onOtherWaysToHelpLinkClicked}
        >
          Other Ways to Help
        </span>
        <span
          className={"SubNavigable__Nav__Link"}
          onClick={this.onContactUsLinkClicked}
        >
          Contact Us
        </span>
      </div>
    );
  }

  renderEventsPageNavSection(): React.ReactElement {
    const normalizedHash = normalizeUrlHash(this.state.hash);
    return (
      <div className="SubNavigable__Nav">
        <span className="SubNavigable__Nav__Heading">Events</span>

        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.eventsCalendar
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onEventsCalendarLinkClicked}
        >
          Events Calendar
        </span>
        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.hostAnEvent
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onHostAnEventLinkClicked}
        >
          Host an Event
        </span>
      </div>
    );
  }

  renderScholarshipsPageNavSection(): React.ReactElement {
    const normalizedHash = normalizeUrlHash(this.state.hash);
    return (
      <div className="SubNavigable__Nav">
        <span className="SubNavigable__Nav__Heading">Scholarships</span>

        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.scholarshipsList
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onScholarshipsListLinkClicked}
        >
          Scholarships List
        </span>
        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.promoteAScholarship
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onPromoteAScholarshipLinkClicked}
        >
          Promote a Scholarship
        </span>
      </div>
    );
  }

  renderDonatePageNavSection(): React.ReactElement {
    const normalizedHash = normalizeUrlHash(this.state.hash);
    return (
      <div className="SubNavigable__Nav">
        <span className="SubNavigable__Nav__Heading">Donate</span>

        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.donateMoney
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onDonateMoneyLinkClicked}
        >
          Donate Money
        </span>
        <span
          className={
            "SubNavigable__Nav__Link" +
            (normalizedHash === URL_HASHES.donateGoodsAndServices
              ? " SubNavigable__Nav__Link--active"
              : "")
          }
          onClick={this.onDonateGoodsAndServicesLinkClicked}
        >
          Donate Goods or Services
        </span>
      </div>
    );
  }

  onHomeLinkClicked(): void {
    const hash = "#" + URL_HASHES.home;
    window.location.hash = hash;
  }

  onOurMissionLinkClicked(): void {
    const hash = "#" + URL_HASHES.ourMission;
    window.location.hash = hash;
  }

  onOurApproachLinkClicked(): void {
    const hash = "#" + URL_HASHES.ourApproach;
    window.location.hash = hash;
  }

  onOurCommitmentLinkClicked(): void {
    const hash = "#" + URL_HASHES.ourCommitment;
    window.location.hash = hash;
  }

  onWhatWeFundLinkClicked(): void {
    const hash = "#" + URL_HASHES.whatWeFund;
    window.location.hash = hash;
  }

  onContactUsLinkClicked(): void {
    window.open(EXTERNAL_LINKS.contactUsForm, "_blank");
  }

  onVolunteerLinkClicked(): void {
    const hash = "#" + URL_HASHES.volunteer;
    window.location.hash = hash;
  }

  onOtherWaysToHelpLinkClicked(): void {
    const hash = "#" + URL_HASHES.otherWaysToHelp;
    window.location.hash = hash;
  }

  onSupportersLinkClicked(): void {
    window.open(
      "https://openslf.github.io/collaboration-graph/view-expanded?org=aclef",
      "_blank"
    );
  }

  onEventsCalendarLinkClicked(): void {
    const hash = "#" + URL_HASHES.eventsCalendar;
    window.location.hash = hash;
  }

  onHostAnEventLinkClicked(): void {
    const hash = "#" + URL_HASHES.hostAnEvent;
    window.location.hash = hash;
  }

  onScholarshipsListLinkClicked(): void {
    const hash = "#" + URL_HASHES.scholarshipsList;
    window.location.hash = hash;
  }

  onBrightChildScholarshipLearnMoreButtonClicked(): void {
    const hash = "#" + URL_HASHES.brightChildScholarshipDetails;
    window.location.hash = hash;
  }

  onBrightChildScholarshipApplyNowButtonClicked(): void {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSeY3xMEHIDRIJAVb7tmUvZB0nn62dV9oHH-U1JUsHifdc_udA/viewform?usp=sf_link",
      "_blank"
    );
  }

  onPromoteAScholarshipLinkClicked(): void {
    const hash = "#" + URL_HASHES.promoteAScholarship;
    window.location.hash = hash;
  }

  onDonateMoneyLinkClicked(): void {
    const hash = "#" + URL_HASHES.donateMoney;
    window.location.hash = hash;
  }

  onDonateGoodsAndServicesLinkClicked(): void {
    const hash = "#" + URL_HASHES.donateGoodsAndServices;
    window.location.hash = hash;
  }

  onDonationAmountTextInputChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const donationAmount = event.target.value;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        donationAmount,
      },
    }));
  }

  onCoverTransactionCostsCheckboxChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const coverTransactionCosts = event.target.checked;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        coverTransactionCosts,
      },
    }));
  }

  onDonorFirstNameTextInputChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const firstName = event.target.value;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        firstName,
      },
    }));
  }

  onDonorLastNameTextInputChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const lastName = event.target.value;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        lastName,
      },
    }));
  }

  onDonorPhoneNumberTextInputChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const phoneNumber = event.target.value;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        phoneNumber,
      },
    }));
  }

  onDonorEmailAddressTextInputChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const emailAddress = event.target.value;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        emailAddress,
      },
    }));
  }

  onDonorMailingAddressTextInputChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const mailingAddress = event.target.value;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        mailingAddress,
      },
    }));
  }

  onCreditCardNumberTextInputChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const creditCardNumber = event.target.value;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        creditCardNumber,
      },
    }));
  }

  onCreditCardExpirationDateTextInputChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const creditCardExpirationDate = event.target.value;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        creditCardExpirationDate,
      },
    }));
  }

  onCvvTextInputChanged(event: React.ChangeEvent<HTMLInputElement>): void {
    const cvv = event.target.value;
    this.setState((prevState) => ({
      donationForm: {
        ...prevState.donationForm,
        cvv,
      },
    }));
  }

  onCompleteDonationButtonClicked(): void {
    window.open(
      "https://openslf.github.io/secure-donation?transaction_id=" +
        Math.random().toString(16).replace("0.", "") +
        "_" +
        Date.now().toString(16),
      "_blank"
    );
  }

  onSubscribeToMailListLinkClicked(): void {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSe6Z_tr4-iisB0gw-8Oa7hGbDH2QyGIFyHoBjeOD4lc_FGwxg/viewform?usp=sf_link",
      "_blank"
    );
  }

  onFacebookLinkClicked(): void {
    window.open(
      "https://www.facebook.com/people/Accessible-Computer-Literacy-Education-Foundation/61562709897962/",
      "_blank"
    );
  }

  onXLinkClicked(): void {
    window.open("https://x.com/aclef_outreach", "_blank");
  }

  onInstagramLinkClicked(): void {
    window.open("https://www.instagram.com/aclef.outreach/", "_blank");
  }

  onYoutubeLinkClicked(): void {
    window.open(
      "https://www.youtube.com/channel/UCgHJ7DaVPm415lmoqemZCZQ",
      "_blank"
    );
  }

  onLinkedinLinkClicked(): void {
    window.open("https://www.linkedin.com/company/aclef-outreach", "_blank");
  }
}

function normalizeUrlHash(hash: string): string {
  return hash.replace(/^#/, "").replace(/\/$/, "");
}

function identityFunctionForTypesafeTypeCast<T>(value: T): T {
  return value;
}

function resetScroll(): void {
  window.scrollTo(0, 0);
}

interface ScholarshipProps {
  readonly name: string;
  readonly deadline: Date;
  readonly children: React.ReactNode;
  readonly dateDotNow: number;
  readonly onLearnMoreButtonClicked?: () => void;
}

function Scholarship({
  name,
  deadline,
  children,
  dateDotNow,
  onLearnMoreButtonClicked,
}: ScholarshipProps): React.ReactElement {
  const prettyPrintedDeadline = deadline.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const isDeadlinePassed = dateDotNow > +deadline;
  return (
    <section
      className={
        "Scholarship" + (isDeadlinePassed ? " Scholarship--deadlinePassed" : "")
      }
    >
      <h3>{name}</h3>

      <p
        className={
          "Scholarship__Deadline" +
          (isDeadlinePassed ? " Scholarship__Deadline--deadlinePassed" : "")
        }
      >
        Application due {prettyPrintedDeadline}
      </p>

      {children}

      {isDeadlinePassed ? (
        <button className="Button" disabled>
          Deadline Passed
        </button>
      ) : (
        <button className="Button" onClick={onLearnMoreButtonClicked}>
          Learn More
        </button>
      )}
    </section>
  );
}
