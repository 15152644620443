type AnyReactProps = Record<string, unknown>;

export function Facebook(props: AnyReactProps): React.ReactElement {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="575.90668"
      height="575.90668"
      viewBox="0 0 575.90668 575.90668"
      {...props}
    >
      <g transform="scale(9.062227823209026) translate(-0.0019064588705077767 -316.2403869628906)">
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,580.29333)">
          <g transform="scale(0.1)">
            <path
              className="SocialMediaLink__Background"
              d="m 476.629,1742.07 c 0,-131.62 -106.695,-238.32 -238.313,-238.32 C 106.695,1503.75 0,1610.45 0,1742.07 c 0,131.61 106.695,238.31 238.316,238.31 131.618,0 238.313,-106.7 238.313,-238.31"
            />

            <path
              className="SocialMediaLink__Foreground"
              d="m 264.672,1590.28 v 132.81 h 44.285 l 8.426,54.94 h -52.711 v 35.65 c 0,15.03 7.359,29.68 30.969,29.68 h 23.968 v 46.78 c 0,0 -21.75,3.71 -42.543,3.71 -43.414,0 -71.789,-26.31 -71.789,-73.95 v -41.87 H 157.02 v -54.94 h 48.257 v -132.81 h 59.395"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function X(props: AnyReactProps): React.ReactElement {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="575.90668"
      height="575.90668"
      viewBox="0 0 575.90668 575.90668"
      {...props}
    >
      <g transform="scale(9.062227823209026) translate(-123.53380584716797 -316.2403564453125)">
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,580.29333)">
          <g transform="scale(0.1)">
            <path
              className="SocialMediaLink__Background"
              d="m 1403.12,1742.07 c 0,-131.62 -106.69,-238.32 -238.31,-238.32 -131.62,0 -238.314,106.7 -238.314,238.32 0,131.61 106.694,238.31 238.314,238.31 131.62,0 238.31,-106.7 238.31,-238.31"
            />

            <path
              className="SocialMediaLink__Foreground"
              d="m 1159.35,1728.98 -8.39,12.01 -66.83,95.59 h 28.77 l 53.93,-77.14 8.4,-12.02 70.1,-100.27 h -28.77 l -57.21,81.82 z m 25.66,29.82 79.04,91.88 h -18.73 l -68.63,-79.78 -54.82,79.78 h -63.22 l 82.89,-120.64 -82.89,-96.35 h 18.73 l 72.48,84.25 57.89,-84.25 h 63.22 L 1185,1758.8 h 0.01"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Instagram(props: AnyReactProps): React.ReactElement {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="575.90668"
      height="575.90668"
      viewBox="0 0 575.90668 575.90668"
      {...props}
    >
      <g transform="scale(9.062227823209026) translate(-247.06539916992188 -316.2403564453125)">
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,580.29333)">
          <g transform="scale(0.1)">
            <path
              className="SocialMediaLink__Background"
              d="m 2329.62,1742.07 c 0,-131.62 -106.7,-238.32 -238.32,-238.32 -131.61,0 -238.31,106.7 -238.31,238.32 0,131.61 106.7,238.31 238.31,238.31 131.62,0 238.32,-106.7 238.32,-238.31"
            />

            <path
              className="SocialMediaLink__Foreground"
              d="m 2156.97,1821.8 c -8.03,0 -14.54,-6.51 -14.54,-14.54 0,-8.03 6.51,-14.55 14.54,-14.55 8.03,0 14.54,6.52 14.54,14.55 0,8.03 -6.51,14.54 -14.54,14.54"
            />

            <path
              className="SocialMediaLink__Foreground"
              d="m 2092.32,1702.94 c -21.57,0 -39.13,17.55 -39.13,39.13 0,21.57 17.56,39.12 39.13,39.12 21.58,0 39.13,-17.55 39.13,-39.12 0,-21.58 -17.55,-39.13 -39.13,-39.13 z m 0,100.21 c -33.68,0 -61.08,-27.4 -61.08,-61.08 0,-33.69 27.4,-61.09 61.08,-61.09 33.68,0 61.09,27.4 61.09,61.09 0,33.68 -27.41,61.08 -61.09,61.08"
            />

            <path
              className="SocialMediaLink__Foreground"
              d="m 2041.79,1842.75 c -28.22,0 -51.17,-22.95 -51.17,-51.17 v -99.03 c 0,-28.22 22.95,-51.17 51.17,-51.17 h 99.03 c 28.21,0 51.17,22.95 51.17,51.17 v 99.03 c 0,28.22 -22.96,51.17 -51.17,51.17 z m 99.03,-224.7 h -99.03 c -41.08,0 -74.5,33.42 -74.5,74.5 v 99.03 c 0,41.08 33.42,74.5 74.5,74.5 h 99.03 c 41.08,0 74.5,-33.42 74.5,-74.5 v -99.03 c 0,-41.08 -33.42,-74.5 -74.5,-74.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Youtube(props: AnyReactProps): React.ReactElement {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="575.90668"
      height="575.90668"
      viewBox="0 0 575.90668 575.90668"
      {...props}
    >
      <g transform="scale(9.062227823209026) translate(-123.53327178955078 -405.2383728027344)">
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,580.29333)">
          <g transform="scale(0.1)">
            <path
              className="SocialMediaLink__Background"
              d="m 1403.12,1074.58 c 0,-131.619 -106.69,-238.318 -238.31,-238.318 -131.62,0 -238.318,106.699 -238.318,238.318 0,131.61 106.698,238.31 238.318,238.31 131.62,0 238.31,-106.7 238.31,-238.31"
            />

            <path
              className="SocialMediaLink__Foreground"
              d="m 1204.16,1070.05 -58.24,-28.82 c -2.29,-1.24 -10.05,0.42 -10.05,3.01 v 59.16 c 0,2.63 7.83,4.28 10.11,2.98 l 55.76,-30.34 c 2.34,-1.33 4.79,-4.71 2.42,-5.99 z m 76.4,34.75 c 0,28.05 -22.75,50.8 -50.8,50.8 h -129.9 c -28.05,0 -50.8,-22.75 -50.8,-50.8 v -60.45 c 0,-28.05 22.75,-50.799 50.8,-50.799 h 129.9 c 28.05,0 50.8,22.749 50.8,50.799 v 60.45"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Linkedin(props: AnyReactProps): React.ReactElement {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="575.90668"
      height="575.90668"
      viewBox="0 0 575.90668 575.90668"
      {...props}
    >
      <g transform="scale(9.062227823209026) translate(-0.0019064588705077767 -494.2361145019531)">
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,580.29333)">
          <g transform="scale(0.1)">
            <path
              className="SocialMediaLink__Background"
              d="M 476.629,407.09 C 476.629,275.469 369.934,168.77 238.316,168.77 106.695,168.77 0,275.469 0,407.09 c 0,131.609 106.695,238.312 238.316,238.312 131.618,0 238.313,-106.703 238.313,-238.312"
            />

            <path
              className="SocialMediaLink__Foreground"
              d="m 163.328,528.301 c 13.102,0 23.719,-10.629 23.719,-23.699 0,-13.102 -10.617,-23.743 -23.719,-23.743 -13.137,0 -23.754,10.641 -23.754,23.743 0,13.07 10.617,23.699 23.754,23.699 m -20.492,-65.399 h 40.973 V 331.281 h -40.973 v 131.621"
            />

            <path
              className="SocialMediaLink__Foreground"
              d="m 209.477,462.902 h 39.242 v -18.011 h 0.539 c 5.469,10.359 18.812,21.257 38.738,21.257 41.418,0 49.059,-27.238 49.059,-62.679 v -72.188 h -40.883 v 63.989 c 0,15.269 -0.297,34.902 -21.262,34.902 -21.297,0 -24.547,-16.621 -24.547,-33.793 v -65.098 h -40.886 v 131.621"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
